<template lang="pug">
section.search-section.products(v-if="records.length")
  .search-section__header
    .search-section__title Recently browsed products
    .search-section__actions
      a.search-section__clear(
        href="#"
        @click.prevent="clear") Clear
  .search-section__content
    .search-grid
      nuxt-link.search-stamp(
        v-for="i, k in records"
        :key="k"
        :to="getRoute(i)"
        @click.native="track({ type: 'recent-product', position: k, product_id: i.id })")
        .search-stamp__thumbnail(v-image="i.thumbnail")
        .search-stamp__info
          .search-stamp__name(v-text="i.name")
          .search-stamp__description(v-if="i.brand") By {{ i.brand.name }}
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import section from './section'

export default {
  mixins: [section],

  computed: {
    ...mapGetters({
      records: 'history/products'
    }),

    sectionName: () => 'RECENT_PRODUCTS'
  },

  methods: {
    ...mapMutations({
      clear: 'history/clearProducts'
    }),

    getRoute (i) {
      return this.$links.product({
        id: i.id,
        name: i.name,
        brand: i.brand?.name
      })
    }
  }
}
</script>
