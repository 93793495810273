import DirectTag from './DirectTag'
import ExpressTag from './ExpressTag'
import HelpTag from './HelpTag'
import StatusTag from './StatusTag'
import LinkTag from './LinkTag'
import RedirectRequestInfoTag from './RedirectRequestInfoTag'

export default {
  DirectTag,
  ExpressTag,
  HelpTag,
  StatusTag,
  LinkTag,
  RedirectRequestInfoTag
}
