import { render, staticRenderFns } from "./RecentKeywordsSection.vue?vue&type=template&id=991ff42e&scoped=true&lang=pug&"
import script from "./RecentKeywordsSection.vue?vue&type=script&lang=js&"
export * from "./RecentKeywordsSection.vue?vue&type=script&lang=js&"
import style0 from "./RecentKeywordsSection.vue?vue&type=style&index=0&id=991ff42e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "991ff42e",
  null
  
)

export default component.exports