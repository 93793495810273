import { render, staticRenderFns } from "./RecentProductsSection.vue?vue&type=template&id=72d2fcb9&lang=pug&"
import script from "./RecentProductsSection.vue?vue&type=script&lang=js&"
export * from "./RecentProductsSection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports