export const state = () => ({
  data: {}
})

export const getters = {
  all: (s, g) => s.data || {},
  searchTrendingKeywords: (s, g) => g.all.searchTrendingKeywords || [],
  hideCareersPage: (s, g) => !!g.all.ui?.hideCareersPage,
  hidePodGrowFilter: (s, g) => !!g.all.ui?.hidePodGrowFilter,
  trackProductImpressions: (s, g) => !!g.all.ui?.trackProductImpressions
}

export const mutations = {
  sync (state, items = {}) {
    state.data = {
      ...items
    }
  }
}

export const actions = {
  getRemoteConfigs ({ state, commit }) {
    return this.$contentful.client
      .getEntries({
        content_type: 'settings'
      })
      .then(res => {
        const items = res.items
          .map(i => i.fields)
          .reduce((data, i) => ({ ...data, [i.key]: i.value }), {})
        commit('sync', items)
      })
  }
}
