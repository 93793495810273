<template lang="pug">
section.search-section.recent-keywords(v-if="records.length")
  .search-section__header
    .search-section__title Recently searched keywords
    .search-section__actions
      a.search-section__clear(
        href="#"
        @click.prevent="clear") Clear

  .search-section__content
    nuxt-link(
      v-for="i, k in records"
      :key="k"
      v-text="i"
      :to="getRoute(i)"
      @click.native="track({ type: 'recent-keyword', keyword: i, position: k })")
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { serializeQuery } from '~/utilities/catalog'
import section from './section'

export default {
  mixins: [section],

  computed: {
    ...mapGetters({
      records: 'history/keywords'
    }),

    sectionName: () => 'RECENT_KEYWORDS'
  },

  methods: {
    ...mapMutations({
      clear: 'history/clearKeywords'
    }),

    getRoute (term) {
      return {
        name: 'products',
        query: serializeQuery({
          term
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.recent-keywords {
  $hover: darken(#f5eaf0, 2);
  .search-section__content {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    > a {
      min-width: 0;
      padding: 4px 6px;
      background: $--color-placeholder;
      border-radius: 4px;
      &:hover {
        background: $hover;
      }
    }
  }
}
</style>
