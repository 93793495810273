import Vue from 'vue'
import states from '~/assets/states.json'

export const state = () => ({
  current: 'NY'
})

export const getters = {
  states: () => states,

  current: (state, getters) => getters.states.find(i => i.abbreviation === state.current)
}

export const mutations = {
  navigate (state, abbr) {
    Vue.set(state, 'current', abbr)
  }
}
