import { serializeQuery } from '~/utilities/catalog'
import Vue from 'vue'

export const slugger = s => {
  if (!s) return ''
  return s
    .replace(/[`~!@#$%^&*()_\-+=\\[\]{};:'"\\|\\/,.<>?\s]/g, ' ')
    .toLowerCase()
    .replace(/^\s+|\s+$/gm, '')
    .replace(/\s+/g, '-')
}

export default (context, inject) => {
  const links = {
    catalog: ({ query = {} } = {}) => ({
      name: 'products',
      query: serializeQuery(query)
    }),

    brand: ({ id, name } = {}) => ({
      name: '_brand',
      params: {
        slug: slugger(name),
        id
      }
    }),

    product: ({ id, name, brand, variant, variantId, query, hash } = {}) => ({
      name: '_product',
      query: {
        sku: variantId ? `${variantId}-${slugger(variant)}` : undefined,
        ...query
      },
      params: {
        slug: `${slugger(name)}-${slugger(brand)}`,
        id
      },
      hash
    }),

    claim: ({ number } = {}) => ({
      name: 'claim',
      query: {
        'TICKET.invoice_number': number
      }
    }),

    back: (to) => {
      if (process.client) {
        const { from } = context
        if (from && from.name === to.name) return { ...from }
        return to
      }
    },

    hash: str => str.split('').reduce((prev, curr) => Math.imul(31, prev) + curr.charCodeAt(0) | 0, 0).toString().slice(-6)
  }

  if (context.isDev) {
    Vue.prototype.$links = links
    context.app.$links = links
    context.store.$links = links
  }

  inject('links', links)
}
