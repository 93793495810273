<template lang="pug">
el-popover(
  ref="vendorPreQualifiedPopoverRef"
  placement="bottom"
  trigger="manual"
  popper-class="el-popover--primary")
  .qualified-content.flex.aic
    .logo.ml-1
      img(
      src="/img/pre-qual-marketing-icon.svg"
      alt="Pod Foods Pre-Qualification")
    .message
      .text You are pre-qualified for a loan!
      //- .value Up to {{ user.kanmon_prequalified_amount_cents | intCents }}
    .close(@click="closeQualifiedPopup")
      i.bx.bx-x.bx-6
  nuxt-link.el-button.el-button--ghost.get-capital.pf-nowrap.mr-2(
    slot="reference"
    :to="{ name: 'capital' }"
    title="Get Capital"
    @click.native="closeQualifiedPopup(true)")
    i.bx.bx-dollar-circle.pf-icon
    span &nbsp;&nbsp;Get Capital
    i.bell.bx.bxs-bell-ring(v-if="!hideCapitalBell")
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GetCapitalCta',

  computed: {
    ...mapGetters({
      user: 'authentication/user'
    }),

    hideCapitalBell () {
      return this.$preferences?.get('$hideVendorCapitalBell', false)
    }
  },

  async mounted () {
    await this.$nextTick()
    const vendorPreQualCents = this.user?.kanmon_prequalified_amount_cents

    if (vendorPreQualCents !== this.$preferences.get('$vendorKanmonPreQualifiedValue')) {
      this.$preferences.set('$vendorKanmonPreQualifiedValue', vendorPreQualCents)
      this.$preferences.set('$hide_vendorPreQualifiedPopoverRef', false)
      vendorPreQualCents && this.$refs.vendorPreQualifiedPopoverRef?.doShow()
      return
    }

    if (vendorPreQualCents && !this.$preferences.get('$hide_vendorPreQualifiedPopoverRef')) {
      this.$refs.vendorPreQualifiedPopoverRef?.doShow()
    }
  },

  methods: {
    closeQualifiedPopup (tracking = false) {
      if (tracking) this.$amplitude.track('CLICK_GET_CAPITAL')
      this.$refs.vendorPreQualifiedPopoverRef?.doClose()
      this.$preferences.set('$hide_vendorPreQualifiedPopoverRef', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-popover {
  .qualified-content {
    gap: 1em;
    .value {
      font-weight: bold;
      font-size: 16px;
      margin-top: 2px;
    }
    .close {
      cursor: pointer;
    }
  }
}
.get-capital {
  .pf-icon {
    color: $--color-primary;
  }
  .bell {
    margin-left: 6px;
    font-size: 18px;
    animation: ring 4s .7s ease-in-out infinite;
    transform-origin: 50% 4px;
    color: $--color-primary;
  }
  @include media(sm-down) {
    border: none;
    color: $--color-primary;
    font-weight: $--font-weight-bold;
    font-size: 12px;
    text-transform: uppercase;
    .pf-icon {
      display: none;
    }
  }
}
</style>
