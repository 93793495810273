<template lang="pug">
section.search-section.brands(v-if="records.length")
  .search-section__header
    .search-section__title Recently browsed brands
    .search-section__actions
      a.search-section__clear(
        href="#"
        @click.prevent="clear") Clear
  .search-section__content
    .search-grid
      nuxt-link.search-stamp(
        v-for="i, k in records"
        :key="k"
        :to="getRoute(i)"
        @click.native="track({ type: 'recent-brand', position: k, brand_id: i.id })")
        .search-stamp__thumbnail(v-image="i.thumbnail")
        .search-stamp__info
          .search-stamp__name(v-text="i.name")
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import section from './section'

export default {
  mixins: [section],

  computed: {
    ...mapGetters({
      records: 'history/brands'
    }),

    sectionName: () => 'RECENT_BRANDS'
  },

  methods: {
    ...mapMutations({
      clear: 'history/clearBrands'
    }),

    getRoute (i) {
      return this.$links.brand({
        id: i.id,
        name: i.name
      })
    }
  }
}
</script>
