import Vue from 'vue'

Vue.directive('image', (e, binding) => {
  const url = binding.value
  // const mods = binding.modifiers
  if (url === undefined || binding.value === binding.oldValue) return
  if (e.tagName === 'IMG') e.src = url
  else e.style.backgroundImage = `url('${url}')`
})

Vue.directive('multiline-error', {
  inserted (e, binding, item) {
    const element = item?.elm
    const component = item?.componentInstance
    const form = component?.elForm
    if (!component || !form) return

    const resizer = new ResizeObserver(entries => {
      const gap = entries[0].target.clientHeight - 16
      if (gap > 0)
        element.style.marginBottom = 22 + gap + 'px'
      else
        element.style.marginBottom = ''
    })

    component.$watch('validateMessage', error => {
      if (!error) return
      component.$nextTick(() => {
        const message = element?.querySelector('.el-form-item__error')
        if (!message) return
        resizer.observe(message)
      })
    })
  }
})

Vue.directive('horizontalScroll', {
  inserted (el, binding) {
    el.hscroll = e => {
      const scroller = el
      if (!window || !scroller) return
      if (el.scrollWidth <= el.clientWidth) return
      e.preventDefault()
      const delta = e.deltaX || e.deltaY
      scroller.scrollLeft += delta
    }
    el.addEventListener('wheel', el.hscroll)
  },

  unbind (el) {
    el.removeEventListener('wheel', el.hscroll)
  }
})

const inserted = function (el, binding) {
  if (typeof window === 'undefined' || !('IntersectionObserver' in window)) return

  const modifiers = binding.modifiers || {}
  const value = binding.value

  const { handler, options } = typeof value === 'object' ? value : { handler: value, options: {} }

  const observer = new IntersectionObserver((entries = [], observer) => {
    /* istanbul ignore if */
    if (!el._observe) return // Just in case, should never fire

    const isIntersecting = entries.some(entry => entry.isIntersecting)

    // If is not quiet or has already been
    // initted, invoke the user callback
    if (
      handler && (
        !modifiers.quiet ||
        el._observe.init
      ) && (
        !modifiers.once ||
        isIntersecting ||
        !el._observe.init
      )
    ) {
      handler(entries, observer, isIntersecting)
    }

    if (isIntersecting && modifiers.once) unbind(el)
    else el._observe.init = true
  }, options)

  el._observe = { init: false, observer }

  observer.observe(el)
}

const ellipsisHandler = el => {
  if (el.offsetWidth >= el.scrollWidth && el._tooltip) {
    el._tooltip?.dispose()
    delete el._tooltip
    delete el._tooltipOldShow
  }
}
Vue.directive('ellipsis', {
  inserted: ellipsisHandler,
  componentUpdated: ellipsisHandler
})

const unbind = function (el) {
  /* istanbul ignore if */
  if (!el._observe) return

  el._observe.observer.unobserve(el)
  delete el._observe
}

Vue.directive('intersect', {
  inserted,
  unbind
})
