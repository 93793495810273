<template lang="pug">
.user-box
  client-only
    .for-desktop
      template(v-if="logged")
        .user-links.broker
          BrokerCompanies.mr-2(v-if="isBroker && isDesktop")

          GetCapitalCta(v-if="isDesktop && isVendor && !isBroker && !isCapitalPage")

          nuxt-link.el-button.el-button--ghost.to-cart.pf-nowrap(
            v-if="isBuyer"
            :to="{ name: 'cart' }"
            title="Cart"
            @click.native="$amplitude.track('CLICK_CART')")
            i.icon.pf-icon.bx.bxs-cart-alt
            template(v-if="cartItemsCount > 0")
              span.counter.mx-1(v-if="cartItemsCount") {{ cartItemsCount | number }}
              span.value.dib(v-if="cartSummary && cartSummary.total") {{ cartSummary.total | cents }}

          nuxt-link.el-button.el-button--primary.to-dashboard(
            :to="toDashboard"
            title="Dashboard"
            @click.native="$amplitude.track('CLICK_DASHBOARD')")
            i.pf-icon.bx.bx-user-circle.mr(size="16")
            span Dashboard

      template(v-else)
        nuxt-link.el-button.el-button--ghost(:to="{ name: 'login' }" title="Login" @click.native="$amplitude.track('CLICK_HOME_LOGIN')") Login
        nuxt-link.el-button.el-button--primary(:to="{ name: 'register' }" title="Register" @click.native="$amplitude.track('CLICK_SIGN_UP')") Start with us

    .for-mobile
      template(v-if="logged")
        nuxt-link.icon.to-cart(
          v-if="isBuyer"
          :to="{ name: 'cart' }"
          title="Cart")
          i.pf-icon.bx.bx-cart-alt
          span.counter(v-if="cartItemsCount") {{ cartItemsCount | number }}

        BrokerCompanies(v-if="isBroker && !isDesktop")

        nuxt-link.icon.to-dashboard(
          :to="toDashboard"
          title="Dashboard")
          i.pf-icon.bx.bx-user

        GetCapitalCta(v-if="!isDesktop && isVendor && !isBroker && !isCapitalPage")

      template(v-else)
        el-dropdown.dropdown(
          placement="bottom"
          trigger="click"
          width="300")
          span
            i.pf-icon.bx.bx-user
          el-dropdown-menu(slot="dropdown")
            el-dropdown-item
              nuxt-link(
                :to="{ name: 'login' }"
                title="Login"
                @click.native="$amplitude.track('CLICK_HOME_LOGIN')") Login
            el-dropdown-item
              nuxt-link(
                :to="{ name: 'register' }"
                title="Register"
                @click.native="$amplitude.track('CLICK_SIGN_UP')") Start with us
</template>

<script>
import { mapGetters } from 'vuex'
import GetCapitalCta from './GetCapitalCta'
import BrokerCompanies from './BrokerCompanies'

export default {
  name: 'UserBox',

  components: {
    GetCapitalCta,
    BrokerCompanies
  },

  computed: {
    ...mapGetters({
      logged: 'authentication/logged',
      isBroker: 'authentication/isBroker',
      isVendor: 'authentication/isVendor',
      isBuyer: 'authentication/isBuyer',
      user: 'authentication/user',
      toDashboard: 'authentication/toDashboard',
      cartItemsCount: 'cart/itemsCount',
      cartSummary: 'cart/summary'
    }),

    isCapitalPage () {
      return this.$route.name?.startsWith('capital')
    },

    isDesktop () {
      if (!process.client) return false
      return window?.innerWidth >= 992
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .broker-info {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: $--border-light;
  }
}

.for-desktop {
  display: none;
  @include media(md) {
    display: block;
  }

  .broker {
    .icon {
      cursor: pointer;
    }
    .divider {
      border-right: 1px solid $--border-light;
    }

  }
  .to-cart {
    .icon {
      color: $--color-primary;
    }
  }
  span.counter {
    font-weight: $--font-weight-bold;
    color: $--color-primary;
  }
}
.for-mobile {
  display: block;
  @include media(md) {
    display: none;
  }
  a.icon {
    position: relative;
    display: inline-block;
    padding: 12px;
    span.counter {
      position: absolute;
      top: 6px;
      right: 0;
      background: $--color-danger;
      color: white;
      font-size: 10px;
      line-height: 1em;
      padding: 2px 5px;
      border-radius: 1000px;
    }
  }
}
</style>
