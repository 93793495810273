import { createClient } from 'contentful'
import { INLINES, BLOCKS } from '@contentful/rich-text-types'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

import { CONTENTFUL_SPACE_ID, CONTENTFUL_CDA_ACCESS_TOKEN } from '~/settings'

export const HEADINGS_MAP = {
  h1: BLOCKS.HEADING_1,
  h2: BLOCKS.HEADING_2,
  h3: BLOCKS.HEADING_3,
  h4: BLOCKS.HEADING_4,
  h5: BLOCKS.HEADING_5,
  h6: BLOCKS.HEADING_6
}

export default function (context, inject) {
  const client = createClient({
    space: CONTENTFUL_SPACE_ID,
    accessToken: CONTENTFUL_CDA_ACCESS_TOKEN
  })

  const text = documentToPlainTextString

  const getYoutubeThumbnail = url => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
    const match = url.match(regex)
    return match
      ? `https://i3.ytimg.com/vi/${match[1]}/maxresdefault.jpg`
      : null
  }

  const slug = text => text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036F]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-')

  const html = (node, { openLinkInNewTab = true, renderAssets = true, renderPlayers = true, renderHashes = true } = {}) => {
    const options = {
      renderNode: {}
    }
    if (openLinkInNewTab)
      options.renderNode[INLINES.HYPERLINK] = (node, next) => {
        if (renderPlayers) {
          const url = node.data.uri
          if (url.startsWith('https://www.youtube.com/watch?v=')) {
            return `<figure class="embedded-player"><iframe src="${url.replace('/watch?v=', '/embed/')}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></figure>`
          }
        }
        return `<a href="${node.data.uri}" target="_blank"'}>${next(node.content)}</a>`
      }
    if (renderAssets)
      options.renderNode[BLOCKS.EMBEDDED_ASSET] = node => {
        const src = node.data?.target?.fields?.file?.url
        const size = node.data?.target?.fields?.file?.details?.image
        if (!src) return ''
        return `
          <p class="embedded-asset">
            <img src="${src}" width="${size.width}" height="${size.height}" />
          </p>
        `
      }
    if (renderHashes)
      Object
        .keys(HEADINGS_MAP)
        .forEach(tag => {
          const type = HEADINGS_MAP[tag]
          options.renderNode[type] = (node, next) => `
            <div class="embedded-anchor" id="${tag}-${slug(text(node))}" data-tag="${tag}">
              <${tag}>${next(node.content)}</${tag}>
            </div>  
          `
        })
    return documentToHtmlString(node, options)
  }

  const headings = (node) => {
    return (node?.content || [])
      .filter(i => i.nodeType.startsWith('heading-'))
      .map(i => {
        const tag = i.nodeType.replace('heading-', 'h')
        return {
          type: i.nodeType,
          tag,
          slug: tag + '-' + slug(text(i)),
          html: html(i)
        }
      })
  }

  inject('contentful', {
    client,
    text,
    slug,
    html,
    headings,
    getYoutubeThumbnail
  })
}
