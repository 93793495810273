import Vue from 'vue'
import { each, keyBy } from 'lodash'

export const update = (state, payload) => {
  each(payload, (value, key) => {
    Vue.set(state, key, value)
  })
}

export const merge = (state, payload) => {
  const cloned = JSON.parse(JSON.stringify(payload))
  Vue.set(state, 'all', {
    ...state.all,
    ...keyBy(cloned, 'id')
  })
}

export const mutationTypes = {
  UPDATE: 'update',
  MERGE: 'merge'
}
