<template lang="pug">
el-dialog(
  custom-class="page__dialog purchase-request-modal"
  visible
  :disabled="saving"
  :close-on-click-modal="false"
  @close="saveOnClose")

  .page__dialog-header.page__dialog-header--separated(slot="title")
    .page__dialog-preview
      i.page__dialog-icon.bx.bx-cart-alt
    .page__dialog-info
      .page__dialog-title Sign up for Wholesale pricing
      .page__dialog-description A Pod Foods rep will provide wholesale pricing after you submit a price request below.

  el-form(
    ref="form"
    :model="form"
    :rules="rules"
    :disabled="saving"
    label-position="top")
    p.notice Please fill in your information to make a request to purchase. Once you submit this form, a Pod Foods representative will reach out shortly to confirm the purchase and delivery details.

    el-row(:gutter="16")
      el-col(:sm="12")
        el-form-item.boxed.first-name(label="First name" prop="requested_account.first_name")
          el-input(v-model.trim="form.requested_account.first_name")
      el-col(:sm="12")
        el-form-item.boxed.last-name(label="Last name" prop="requested_account.last_name")
          el-input(v-model.trim="form.requested_account.last_name")

    el-row(:gutter="16")
      el-col(:sm="12")
        el-form-item.boxed.email(label="Email" prop="requested_account.email")
          el-input(v-model="form.requested_account.email")
      el-col(:sm="12")
        el-form-item.boxed.store-name(label="Store name" prop="purchase_request.store_name")
          el-input(v-model="form.purchase_request.store_name")

    el-form-item(label="Select flavors" prop="items")
      .actions
        a.linked(href="#" @click.prevent="handleToggleSelectAll(true)") Select all
        span.mx-1 |
        a.linked(href="#" @click.prevent="handleToggleSelectAll(false)") Deselect all

      .skus
        .sku(
          v-for="v, vk in product.variants"
          :key="vk"
          :class="{ selected: form.items.includes(v.id) }"
          @click="toggleSku(v)")
          .select(@click.stop)
            el-checkbox-group(v-model="form.items")
              el-checkbox(:label="v.id") {{ '' }}
          .preview
            product-variant-stamp.plain(
              :data="v"
              showImage
              read-only
              show-product
              show-variant-name
              show-case-units)
          .price {{ v.id | blurryPrice }}

    el-form-item.boxed.comments(
      label="Comments"
      prop="purchase_request.comment")
      el-input(
        type="textarea"
        rows="3"
        v-model="form.purchase_request.comment")
    el-form-item(prop="requested_account.is_current_retail_partner")
      el-checkbox(v-model="form.requested_account.is_current_retail_partner" ) Are you a current retail partner of Pod Foods?

  .page__dialog-footer(slot="footer")
    el-button.btn(type="primary" @click="submit")
      i.bx.bxs-lock-open.bx-5
      span.ml-1 Wholesale Pricing
</template>

<script>
import { cloneDeep } from 'lodash'
import ProductVariantStamp from '~/components/stamps/ProductVariantStamp'

export const PREFERENCE_FORM_KEY = 'purchaseRequestForm'

export default {
  components: {
    ProductVariantStamp
  },

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      form: {
        items: [],
        requested_account: {
          is_current_retail_partner: false
        },
        purchase_request: {}
      },
      saving: false,
      rules: {
        'requested_account.first_name': { required: true, message: 'Required' },
        'requested_account.last_name': { required: true, message: 'Required' },
        'requested_account.email': {
          required: true,
          ...this.$validator('email')
        },
        'purchase_request.store_name': { required: true, message: 'Required' },
        items: {
          type: 'array',
          required: true,
          min: 1,
          message: 'Please select at least one flavor'
        }
      }
    }
  },

  created () {
    const lastForm = this.$preferences.get(PREFERENCE_FORM_KEY)

    if (lastForm) this.form = { ...lastForm }
  },

  methods: {
    handleToggleSelectAll (isSelect = true) {
      const items = isSelect ? Object.values(this.product.variants).map(e => e.id) : []
      this.$set(this.form, 'items', items)
    },

    toggleSku (sku) {
      const items = this.form.items
      const newItems = items.includes(sku.id)
        ? items.filter(id => id !== sku.id)
        : [...items, sku.id]

      this.$set(this.form, 'items', newItems)
    },

    submit () {
      return this.$refs.form.validate(valid => {
        if (!valid) return
        this.saving = true
        const widget = this.$preferences.get('vendorBrandWidget')
        const brand = this.$preferences.get('vendorBrand')
        return this.$api.catalog
          .createPurchaseRequest({
            ...this.form,
            purchase_request: {
              widget_type: widget,
              brand_name: brand,
              ...this.form.purchase_request,
              purchase_request_items_attributes: this.form.items
                .map(id => ({
                  product_variant_id: id,
                  quantity: 1
                }))
            }
          })
          .then(res => {
            this.$amplitude.track('CLICK_PURCHASE_REQUEST_SUBMIT')
            this.$preferences.clear(PREFERENCE_FORM_KEY)
            this.$emit('done', res)
            this.$emit('close')
          })
          .finally(() => {
            this.saving = false
            if (widget) {
              // Datadog custom action: if coming from vendor widget, push event to Datadog
              this.$rum.instance.addAction('widget', {
                type: widget,
                action: 'request to purchase submitted',
                brand
              })
              // reset widget and brand
              this.$preferences.clear('vendorBrandWidget')
              this.$preferences.clear('vendorBrand')
            }
          })
      })
    },

    saveOnClose () {
      this.$preferences.set(PREFERENCE_FORM_KEY, cloneDeep(this.form))
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.actions {
  display: flex;
  justify-content: flex-end;
  margin-top: -2em;
}
::v-deep {
  .purchase-request-modal {
    max-width: 700px;
  }
}
.notice {
  font-size: 12px;
  color: $--color-text-secondary;
  margin: 0 0 2em 0;
}
.skus {
  margin-top: 0.5em;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  .sku {
    cursor: pointer;
    display: flex;
    padding: .5em;
    align-items: center;
    border: $--border-light;
    border-radius: $--radius;

    &.selected {
      border-color: $--color-primary;
    }
    > * {
      min-width: 0;
    }
    > .preview {
      flex: 1;
      ::v-deep {
        .variant-name {
          font-weight: $--font-weight-bold;
        }
      }
    }
    > .price {
      filter: blur(3px);
    }
  }
}
</style>
